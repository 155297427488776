import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/welcome/Hero";
import ContentOne from "../sections/refer/ContentOne";
import ContentTwo from "../sections/refer/ContentTwo";
import ContentThree from "../sections/refer/ContentThree";
import Testimonial from "../sections/refer/Testimonial";
import HeaderButton from '../components/HeaderButton'

const Welcome = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right dark-mode-texts dropdown-left",
          headerButton: (<HeaderButton />),
          footerStyle: "style2",
        }}
      >
        <Hero className="bg-blue-1 position-relative pt-20 pt-sm-24 pt-lg-34 pb-9 pb-lg-32" />
        <ContentOne className="pt-13 pt-lg-28 pb-13 pb-lg-26" />
        <ContentTwo className="bg-default-4 pt-15 pt-lg-30 pb-15 pb-lg-26" />
        <ContentThree className="bg-dark-cloud pt-30 pt-lg-28 pb-15 pb-lg-30" />
        <Testimonial />
      </PageWrapper>
    </>
  );
};
export default Welcome;
